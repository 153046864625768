import React, { useState, useEffect, CSSProperties } from 'react';
import classnames from 'classnames';
import { StylesConfig } from 'react-select';
import Icon from '../Icon/Icon';
import { ChevronLeft, DoubleChevronLeft, ChevronRight, DoubleChevronRight } from '../Icon/Icons';
import styles from './Pagination.module.scss';
import Select from '../Select/Select';
import { ValueType } from 'react-select';
import { SelectOption } from 'src/components/ReleaseTable/Filters';

export interface PaginationProps {
  current: number;
  total: number;
  onChange: (page: number) => void;
  onItemsPerPageChange: (selectedOption: ValueType<SelectOption, false>) => void;
  itemsPerPageValue: number;
}

export enum ButtonType {
  NEXT= 'NEXT',
  PREVIOUS = 'PREVIOUS',
  FIRST = 'FIRST',
  LAST = 'LAST',
}

export interface IconPageButtonProps {
  onClick: () => void;
  type: ButtonType;
  hidden?: boolean;
}

export interface PageButtonProps {
  page: number;
  isActive: boolean;
  onClick: () => void;
}

export const calcPageRange = (page: number, total: number): number[] => {
  let start: number;
  let end: number;

  if (total <= 10) {
    start = 1;
    end = total;
  } else {

    if (page <= 6) {
      start = 1;
      end = 10;
    } else if (page >= (total - 9)) {
      start = total - 9;
      end = total;
    } else {
      start = page - 5;
      end = start + 9;
    }

  }

  const length = end - start + 1;
  return Array.from({ length }, (_, i) => start + i);
};

export const PageButton: React.FC<PageButtonProps> = ({ page, isActive, onClick }) => {

  const css = classnames({
    [styles.Pagination__Button]: true,
    [styles['Pagination__Button--outlined']]: !isActive,
    [styles['Pagination__Button--active']]: isActive,
  });

  return (
    <button data-test-id={`pagination_${page}`} className={css} onClick={onClick}>
      {page}
    </button>
  );

};

export const IconPageButton: React.FC<IconPageButtonProps> = ({ type, onClick, hidden }) => {

  let IconComponent;
  switch (type) {
    case ButtonType.FIRST:
      IconComponent = DoubleChevronLeft;
      break;
    case ButtonType.PREVIOUS:
      IconComponent = ChevronLeft;
      break;
    case ButtonType.LAST:
      IconComponent = DoubleChevronRight;
      break;
    default:
      IconComponent = ChevronRight;
  }

  const css = classnames({
    [styles.Pagination__Button]: true,
    [styles['Pagination__Button--hidden']]: hidden,
    [styles['Pagination__Button--spaced--right']]: type === ButtonType.LAST || type === ButtonType.NEXT,
    [styles['Pagination__Button--spaced--left']]: type === ButtonType.FIRST || type === ButtonType.PREVIOUS,
  });

  return (
    <button className={css} onClick={onClick} data-test-id={`pagination_${type}`}>
      <Icon icon={IconComponent} size="21" hasHover/>
    </button>
  );
};
const itemsPerPageStyles: StylesConfig<SelectOption, false> = {
  container: (providedStyles) => ({
    ...providedStyles,
    maxWidth: 200,
    width: '100%',
    marginLeft: 'auto',
  }),
};

const Pagination: React.FC<PaginationProps> = ({ current, total, onChange, onItemsPerPageChange, itemsPerPageValue }) => {
  const [pagesList, setPagesList] = useState<number[]>();
  useEffect(() => {
    setPagesList(calcPageRange(current, total));

  },        [current, total]);

  const goPage = (page: number) => {
    onChange(page);
  };

  const goPrevious = () => {
    goPage(current - 1);
  };

  const goNext = () => {
    goPage(current + 1);
  };

  const goFirst = () => {
    goPage(1);
  };

  const goLast = () => {
    goPage(total);
  };

  const renderPrevious = () => (
    <>
      <li>
        <IconPageButton hidden={current === 1} onClick={goFirst} type={ButtonType.FIRST}/>
      </li>
      <li>
        <IconPageButton hidden={current === 1} onClick={goPrevious} type={ButtonType.PREVIOUS}/>
      </li>
    </>
  );

  const renderNext = () => (
    <>
      <li>
        <IconPageButton hidden={current === total} onClick={goNext} type={ButtonType.NEXT}/>
      </li>
      <li>
        <IconPageButton hidden={current === total} onClick={goLast} type={ButtonType.LAST}/>
      </li>
    </>
  );

  const renderButtons = () => {
    if (pagesList && pagesList.length > 1) {
      return pagesList.map((n: number) => (
        <li key={`pb_${n}`}>
          <PageButton page={n} isActive={n === current} onClick={() => goPage(n)}/>
        </li>
      ));
    }

    return null;
  };

  if (total === 0) {
    return null;
  }

  return (
    <div className={styles.flexbox_container} >
      <div style={{ width: '80%' }}>
        <div style={{ width: '80%', marginLeft: 'auto', marginRight: '0' }}>
        <nav className={styles.Pagination}>
          <p className={styles.Pagination__Count}>
            Page {current} of {total}
          </p>
          <ul className={styles.Pagination__Buttons}>
            {renderPrevious()}
            {renderButtons()}
            {renderNext()}
          </ul>
        </nav>
      </div>
    </div>
    <div style={{ width: '20%', float: 'right', marginTop: '14px' }}>
          < div className={styles.flexbox_item__flexbox_items_per_page}>
            <Select style={{ position: 'releative' }}
              // tslint:disable-next-line:prefer-template
              value = {{ value: itemsPerPageValue.toString(), label: itemsPerPageValue.toString() + ' per page' }}
              placeholder="Items Per Page"
              styles={itemsPerPageStyles}
              options={[
                { value: '10', label: '10 per page' },
                { value: '25', label: '25 per page' },
                { value: '50', label: '50 per page' },
              ]}
              onChange={onItemsPerPageChange}
            />
          </div>
        </div>
  </div>
  );
};

export default Pagination;
