import React from 'react';
import styles from './WorldBanner.module.scss';
import { getConfig } from 'src/config';
import LayoutWrapper from 'src/components/system/LayoutWrapper/LayoutWrapper';

const WorldBanner: React.FC = () => {
  const config = getConfig();

  return (
    <div className={styles.main_img}>
      <img
        src={`https://${config.s3BucketName}/GFK_World2.webp`}
        alt="GfK Boutique Banner"
        className={styles.backgroundImage}
      />
      <div className={styles.overlay}>
        <LayoutWrapper>
            <h1 className={styles.investorUpdate}>GfK Boutique Research</h1>
            <p className={styles.updateText}>
              Strengthen your financial decision-making with the world's
              <br/>most comprehensive point-of-sale data.
            </p>
        </LayoutWrapper>
      </div>
    </div>
  );
};

export default WorldBanner;
