import React from 'react';
import styles from './OverviewText.module.scss';
import Button from 'src/components/system/Button/Button';
import LayoutWrapper from 'src/components/system/LayoutWrapper/LayoutWrapper';
interface TextSectionProps {
  toggleLoginModal: () => void;
  toggleSignupModal: () => void;
}

const TextSection: React.FC<TextSectionProps> = ({ toggleLoginModal, toggleSignupModal }) => {
  return (
    <LayoutWrapper>
      <div className={styles.textSection}>
        <h2 className={styles.textTitle}>Drive performance with real-time consumer demand insights</h2>
        <p className={styles.textParagraph}>
          As tech companies innovate faster and markets change quickly,
          access to the largest consumer electronics and durables point-of-sale global dataset means you can make
          smarter and more timely investment decisions.
        </p>
        <p className={styles.textParagraph}>
          GfK Boutique measures consumer end demand trends,
          offering fundamental and granular insights into industries and
          companies from consumer technology and home appliances to smaller retail segments.
          We use our proprietary data to offer timely, unique and incremental demand analysis that is a true
          reflection of final end demand.
        </p>
        <div className={styles.ctaButtons}>
          <Button onClick={toggleSignupModal}>Sign Up</Button>
          <span className={styles.orText}>or</span>
          <Button onClick={toggleLoginModal}>Login</Button>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default TextSection;
