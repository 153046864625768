import React from 'react';
import { Modal, ModalBody } from 'reactstrap';

interface SignUpModalProps {
  isOpen: boolean;
  toggle: () => void;
}

const SignUpModal: React.FC<SignUpModalProps> = ({ isOpen, toggle }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} className="signup-modal">
      <ModalBody>
        <h1>Sign Up</h1>
        <div>
          <p>Contact Our Customer Success Team To Enquire:</p>
          <p><strong>Phone:</strong> +1 978-618-0184</p>
          <p><strong>Email:</strong> <a href="mailto:Adam.Kurtzer@gfk.com">Adam.Kurtzer@gfk.com</a></p>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SignUpModal;
