import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import styles from './Ovn.module.scss';
import { Badge, Card, CardBody, CardLink, CardText, CardTitle } from 'reactstrap';
import Loading from 'src/components/system/Loading/Loading';
import InlineAlert, { InlineAlertType } from 'src/components/system/InlineAlert/InlineAlert';
import * as getArticles from '../../../graphql/getArticles';
import * as getSectors from '../../../graphql/getSectors';
import ScrollToTopButton from '../../../components/scrollToTopButton';
import Select from '../../system/Select/Select';
import { ValueType } from 'react-select/src/types';

interface OvnProps {
  search?: string;
  sectorKeyFromHome?: number;
}

type SelectOption = { value: string; label: string };

const Ovn: React.FC<OvnProps> = (props: OvnProps) => {

  const [lastItemElement, setLastItemElement] = useState<HTMLDivElement | null>(null);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [hasMoreArticles, setHasMoreArticles] = useState(true);
  const { search, sectorKeyFromHome } = props;
  const [sectorKey, setSectorKey] = useState<number | undefined>(sectorKeyFromHome);

  // tslint:disable-next-line:max-line-length
  const { data, error, loading, fetchMore, refetch }  = useQuery<getArticles.ResultType>(getArticles.query, { variables: { search, sectorKey, skip: 0, take: 60 } });
  const sectorsQuery = useQuery<getSectors.ResultType>(
    getSectors.query,
  );

  const sectorOptions = ((sectorsQuery.data
    && sectorsQuery.data?.sectors) || [])
    .map(({ name, id }) => ({ label: name, value: id.toString() }));

  const handleSectorFilter = (selectedOption: ValueType<SelectOption, false>) => {
    const sectorId = +(selectedOption as SelectOption)?.value;
    refetch({ search, sectorKey: sectorId, skip: 0, take: 60 });
    setSectorKey(sectorId);
  };

  const handleSectorClick = (sectorId: number) => {
    const sectorOption = sectorOptions.find(option => +option.value === sectorId) || null;
    handleSectorFilter(sectorOption);
  };

  useEffect(() => {
    if (lastItemElement && data) {
      const observer = new IntersectionObserver((entries) => {
        const firstEntry = entries[0];
        if (firstEntry.isIntersecting) {
          setIsFetchingMore(true);
          fetchMore({
            variables: {
              search,
              sectorKey,
              skip: data.articles.items.length,
              take: 60,
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              setIsFetchingMore(false);
              // tslint:disable-next-line:no-parameter-reassignment
              if (!fetchMoreResult) return prev;
              if (fetchMoreResult.articles.items.length === 0) {
                setHasMoreArticles(false);
              }
              return Object.assign({}, prev, {
                articles: {
                  ...fetchMoreResult.articles,
                  items: [...prev.articles.items, ...fetchMoreResult.articles.items],
                },
              });
            },
          });
        }
      });
      const timer = setTimeout(() => {
        observer.observe(lastItemElement);
      },                       200);
      return () => {
        clearTimeout(timer);
        observer.disconnect();
      };
    }
  },        [lastItemElement, data, fetchMore, search, sectorKey]);

  const assignRef = (index: number, element: HTMLDivElement | null) => {
    if (data?.articles.items.length === index + 1) {
      setLastItemElement(element);
    }
  };

  return (
    <>
      <div className={styles.sectorFilter}>
        <Select
          placeholder="Filter By Sector"
          value={sectorKey ?
            { label: sectorOptions.find(option => +option.value === sectorKey)?.label || '', value: sectorKey.toString() }
            : null}
          options={sectorOptions}
          onChange={selectedOption => handleSectorFilter(selectedOption)}
          isClearable
          inputId="1"
        />
      </div>
    {
      (loading) ? (<Loading />) : (error) ?
      (<InlineAlert type={InlineAlertType.DataError}><p>Unable to retrieve articles from server</p></InlineAlert>) :
      (data?.articles.items.length === 0) ? <InlineAlert type={InlineAlertType.Info}><p>No articles found</p></InlineAlert> :
      (

      <>
      <div className={styles.newsGrid}>
        {/* tslint:disable-next-line:max-line-length */}
        {data?.articles.items.map((article, index: number) => (
          <div key={article.id}  ref={element => assignRef(index, element)}>
            <Card className={`my-2 ${styles.fillGrid}`}>
            <CardBody style={{ padding: '1em' }}>
              <CardTitle className={styles.title}>{article.brands?.map(brand => brand.name).join(' | ')}</CardTitle>
              {/* tslint:disable-next-line:max-line-length */}
              <small className={`${styles.topright} text-muted`}>{new Date(article.publishedAt).toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric' })}
              </small>
              <CardText style={{ height: '155px', overflowY: 'auto', display: 'block' }}>
                <small className="text-muted">
                  {article.body}
                </small>
              </CardText>
              {/*tslint:disable-next-line:max-line-length */}
              <div className={styles.pill}>
                {(article.country && article.country.name !== 'UNCATEGORISED') &&
                  <Badge className={styles.badge} pill key={article.country.id}>{article.country.name}</Badge>}
                  <Badge className={styles.badge} pill key={article.sector.id}
                                                    onClick={() => handleSectorClick(article.sector.id)}>{article.sector.name}</Badge>
                  {
                    article.source &&
                      <CardLink href={article.source} target="_blank">
                        Source
                      </CardLink>
                  }
                  {
                    article.source2 &&
                      <CardLink href={article.source2} target="_blank">
                        Source 2
                      </CardLink>
                  }
              </div>
            </CardBody>
            </Card>
          </div>
        ))}
      </div>
      { isFetchingMore && hasMoreArticles && <Loading />}
    </>
      )
    }
    <ScrollToTopButton />
    </>
  );
};
export default Ovn;
