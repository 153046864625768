import eachMonthOfInterval from 'date-fns/eachMonthOfInterval';
import eachYearOfInterval from 'date-fns/eachYearOfInterval';
import format from 'date-fns/format';

import { CustomHeaderProps } from '../components/CustomHeader/CustomHeader';

interface CustomHeaderReturnType {
  minMaxRange: Interval;
  availableMonths: Date[];
  availableYears: Date[];
  currentMonth: string;
  currentYear: string;
}

export const useCustomHeader = (
  props: Pick<CustomHeaderProps, 'minimumDate' | 'maximumDate' | 'monthDate' | 'startDate' | 'endDate' | 'isStart'>,
): CustomHeaderReturnType => {
  const { monthDate, minimumDate, maximumDate, startDate, endDate, isStart } = props;

  const currentMonth = format(monthDate, 'MMMM');
  const currentYear = format(monthDate, 'yyyy');

  const minDate = (!isStart && startDate) || minimumDate || new Date('01/01/1970');
  const maxDate = (isStart && endDate) || maximumDate || new Date('01/01/2100');

  const minMaxRange = {
    start: minDate,
    end: maxDate,
  };

  const startOfCurrentYear = format(minDate, 'yyyy') === currentYear ? minDate : new Date(`01/01/${currentYear}`);
  const endOfCurrentYear = format(maxDate, 'yyyy') === currentYear ? maxDate : new Date(`12/31/${currentYear}`);

  const availableMonths = eachMonthOfInterval({
    start: startOfCurrentYear,
    end: endOfCurrentYear,
  });

  const availableYears = eachYearOfInterval(minMaxRange);

  return { minMaxRange, availableMonths, availableYears, currentMonth, currentYear };
};
