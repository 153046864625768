import { Auth } from '@aws-amplify/auth';
import * as React from 'react';
import Button from '../system/Button/Button';
import InlineAlert, { InlineAlertType } from '../system/InlineAlert/InlineAlert';
import Input from '../system/Input/Input';
import { AuthState } from './authenticator';
import { getPasswordPolicyErrorMessage } from './errorMessages';
import styles from './authButtons.module.scss';
import GridContainer from '../system/GridContainer/GridContainer';
import ResendTemporaryPasswordModal from './resendTemporaryPasswordModal';

interface IProps {
  onStateChange: (newState: AuthState, data?: any) => void;
}

interface IState {
  hasSentResetCode: boolean;
  errorMessage: string | null;
  username: string | null;
  showSendCodeButton: boolean;
  isTemporaryPasswordModalOpen: boolean;
}
export default class ForgotPassword extends React.Component<IProps, IState> {
  private inputs: { [key: string]: string } = {};

  constructor(props: IProps) {
    super(props);

    this.state = {
      hasSentResetCode: false,
      errorMessage: null,
      username: null,
      showSendCodeButton: true,
      isTemporaryPasswordModalOpen: false,
    };
  }

  async getResetCode() {
    this.setState({ errorMessage: null });
    const { username } = this.inputs;
    this.setState({ username });

    if (!username || username.trim() === '') {
      this.setState({ errorMessage: 'Email address cannot be empty' });
      return;
    }
    try {
      await Auth.forgotPassword(username.toLowerCase().trim());
      this.setState({ hasSentResetCode: true });
    } catch (err) {
      const error = err as Error & { code?: string };
      if (error.code === 'UserNotFoundException') {
        this.setState({ hasSentResetCode: true });
        this.showError(error);
        return;
      }
      this.setState({ showSendCodeButton: false }); // Hide the button after sending the reset code
      this.showError(error);
    }
  }

  async submitResetCode() {
    this.setState({ errorMessage: null });
    const { username, code, password } = this.inputs;

    if (!code) {
      this.setState({ errorMessage: 'Code cannot be empty' });
      return;
    }

    if (!password) {
      this.setState({ errorMessage: 'New password cannot be empty' });
      return;
    }

    try {
      await Auth.forgotPasswordSubmit(username.toLowerCase(), code, password);
      this.props.onStateChange('signIn');
    } catch (err) {
      this.showError(err);
    }
  }

  toggleTemporaryPasswordModal = () => {
    this.setState({ isTemporaryPasswordModalOpen: !this.state.isTemporaryPasswordModalOpen });
  }

  async getErrorDisplayMessage(error: any) {
    // Some AWS errors have unfriendly error messages, so
    // we identify these by the error's code field and return
    // our own message. Sometimes there is no code, but a __type field.
    const awsErrorCode = error.code || error.__type;

    if (awsErrorCode) {
      switch (awsErrorCode) {
        case 'UserNotFoundException':
          return 'Incorrect username or code.';
        case 'NotAuthorizedException':
          this.toggleTemporaryPasswordModal();
          return 'Password cannot be reset as the account has a temporary password associated with it. ' +
          'Log in using the temporary password instead.';
        case 'InvalidParameterException':
        case 'InvalidPasswordException':
          return getPasswordPolicyErrorMessage('Password');
      }
    }

    // Otherwise, check if the error has a message field
    if (error.message) {
      return error.message;
    }

    // The Amplify library will otherwise throw an error with a string
    if (typeof error === 'string') {
      return error;
    }

    return 'Unknown error occurred.';
  }

  async showError(error: any) {
    const errorMessage = await this.getErrorDisplayMessage(error);
    this.setState({ errorMessage });
  }

  handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    this.inputs[name] = value;
  }

  async onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.keyCode !== 13) {
      return;
    }

    if (!this.state.hasSentResetCode) {
      await this.getResetCode();
    } else {
      await this.submitResetCode();
    }
  }

  getView() {
    return (
      <div>
        <Input
          type="text"
          label="Username"
          name="username"
          key="username"
          placeholder="Enter your email address"
          onChange={e => this.handleInputChange(e as any)}
          // onKeyDown={e => this.onKeyDown(e)}
        />
      </div>
    );
  }

  submitView() {
    return (
      <div>
        <InlineAlert type={InlineAlertType.Info}>
          <p>
            If this email address is registered, you'll receive a password reset code shortly.
            <br/><br/>
            Please enter the reset code below, along with your new password.
          </p>
        </InlineAlert>

        <GridContainer item={true} desktop="1" gap="small">
          <div>
            <Input
              type="text"
              label="Code"
              key="code"
              name="code"
              onChange={e => this.handleInputChange(e as any)}
              // onKeyDown={e => this.onKeyDown(e)}
            />
          </div>
          <div>
            <Input
              type="password"
              label="Password"
              key="password"
              name="password"
              placeholder="New password"
              onChange={e => this.handleInputChange(e as any)}
              // onKeyDown={e => this.onKeyDown(e)}
            />
          </div>
        </GridContainer>
      </div>
    );
  }

  render() {
    const { onStateChange } = this.props;
    const { errorMessage, hasSentResetCode, showSendCodeButton } = this.state;

    const content = hasSentResetCode
      ? this.submitView()
      : this.getView();

    let button = null;

    // if there is an error message neither 'Send code' or 'Submit' button is shown
    if (showSendCodeButton) {
      if (hasSentResetCode) {
        button = (
          <Button onClick={() => this.submitResetCode()} testId="primary">
            Submit
          </Button>
        );
      } else {
        button = (
          <Button onClick={() => this.getResetCode()} testId="primary">
            Send code
          </Button>
        );
      }
    }

    return (
      <div>
        <h1>Reset your password</h1>

          {
            errorMessage
              ? <InlineAlert type={InlineAlertType.DataError}><p>{errorMessage}</p></InlineAlert>
              : null
          }

        {content}

        <div className={styles.buttonContainer}>
          <Button
            secondary={true}
            onClick={() => onStateChange('signIn')}
            testId="back">
            Back to Login
          </Button>{' '}
          {button}
        </div>

        <ResendTemporaryPasswordModal
          isOpen={this.state.isTemporaryPasswordModalOpen}
          toggle={this.toggleTemporaryPasswordModal}
          email={this.state.username!}>
        </ResendTemporaryPasswordModal>
      </div>
    );
  }
}
