import classnames from 'classnames';
import React from 'react';
import Icon from '../Icon/Icon';
import styles from './Button.module.scss';

interface Props {
  type?: 'button' | 'submit' | 'reset' | undefined;
  onClick?: () => void;
  secondary?: boolean;
  disabled?: boolean;
  icon?: React.FC;
  badge?: string;
  testId?: string;
}

const Button: React.FC<Props> = (props) => {
  const classNames = classnames(
    styles['Button'],
    { [styles['Button--secondary']]: !!props.secondary },
    { [styles['Button--with-icon']]: !!props.icon },
  );

  return (
    <button
      type={props.type}
      disabled={props.disabled}
      className={classNames}
      data-testid={props.testId}
      onClick={props.onClick}>
      {props.icon &&
        (<Icon
          size="18"
          className={styles['Button__icon']}
          icon={props.icon}/>)}
      {props.children}

      {props.badge &&
        (<span className={styles['Button__badge']}>({props.badge})</span>)}
    </button>
  );
};

export default Button;
