import classnames from 'classnames';
import add from 'date-fns/add';
import endOfMonth from 'date-fns/endOfMonth';
import endOfWeek from 'date-fns/endOfWeek';
import getWeek from 'date-fns/getWeek';
import isSameDay from 'date-fns/isSameDay';
import isSameWeek from 'date-fns/isSameWeek';
import startOfMonth from 'date-fns/startOfMonth';
import startOfWeek from 'date-fns/startOfWeek';
import sub from 'date-fns/sub';
import React, { forwardRef, useMemo } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';

import styles from './DateRangePicker.scss';
import { CustomHeader } from './components/CustomHeader/CustomHeader';
import { useColorKeyboardSelected, useDateRangePickerState } from './hooks';
import { getDateFormat } from './utils';
export enum CalendarView {
  Week = 'Week',
  Month = 'Month',
}

export type InitialDateRangePickerValue = { startDate?: Date | null; endDate?: Date | null };

export type DateRangePickerProps = Partial<ReactDatePickerProps> & {
  calendarView: CalendarView;
  weekCount?: number;
  maxDate?: Date;
  minDate?: Date;
  monthCount?: number;
  onStartRangeSelect: (startDate: Date | null) => void;
  onEndRangeSelect: (endDate: Date | null) => void;
  direction?: 'row' | 'column';
  selectedDate: InitialDateRangePickerValue;
  isClearable?: boolean;
  startTestId?: string;
  endTestId?: string;
};

export const weekStartsOn = 0;

export const defaultWeekOptions = {
  weekStartsOn,
  firstWeekContainsDate: 3,
} as const;

const weekPickerProps: Partial<ReactDatePickerProps> = {
  showYearDropdown: true,
  weekLabel: 'Week',
  showMonthDropdown: true,
  dropdownMode: 'select',
  showWeekNumbers: true,
};

const monthPickerProps: Partial<ReactDatePickerProps> = {
  dateFormat: 'MMM yyyy',
  showMonthYearPicker: true,
  popperContainer: ({ children }) => <div className={styles.PopperStyle}>{children}</div>,
};

const CustomInput = forwardRef<
  HTMLInputElement,
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
>(({ value, onClick, ...props }, ref) => (
  <div role="button" aria-label="Toggle picker" onClick={onClick}>
    <input ref={ref} value={value} {...props} readOnly={true} />
  </div>
));

export const DateRangePicker = ({
  calendarView,
  weekCount,
  maxDate,
  monthCount,
  onStartRangeSelect,
  onEndRangeSelect,
  direction = 'column',
  minDate,
  // isClearable,
  selectedDate,
  startTestId,
  endTestId,
}: DateRangePickerProps): JSX.Element => {
  const { startDate, endDate, onStartDateSelect, onEndDateSelect } = useDateRangePickerState(
    calendarView,
    onStartRangeSelect,
    onEndRangeSelect,
    selectedDate,
  );

  const { colorKeyboardSelected, onCalendarOpenMonthView, onYearChangeMonthView } = useColorKeyboardSelected(
    calendarView,
    startDate,
    endDate,
  );

  const maximumDate = useMemo(
    () => maxDate && (calendarView === CalendarView.Month ? endOfMonth(maxDate) : endOfWeek(maxDate, { weekStartsOn })),
    [maxDate, calendarView],
  );

  const minimumDate = useMemo(() => {
    let minimumDate = null;
    if (!minDate && !monthCount && !weekCount) {
      return minimumDate;
    }

    if (minDate) {
      minimumDate = minDate;
    }

    if (!minDate && (monthCount || weekCount)) {
      minimumDate = sub(
        new Date(),
        calendarView === CalendarView.Month ? { months: monthCount } : { weeks: weekCount },
      );
    }

    return (
      minimumDate &&
      (calendarView === CalendarView.Month ? startOfMonth(minimumDate) : startOfWeek(minimumDate, { weekStartsOn }))
    );
  },                          [minDate, monthCount, weekCount, calendarView]);

  const formatWeekNumber = (isStart: boolean) => (date: Date) => {
    const isDateOffLimits = (maximumDate && date > maximumDate) || (minimumDate && date < minimumDate);
    const isStartDateAfterEnd = isStart && endDate && date > endDate;
    const isEndDateAfterStart = !isStart && startDate && date < startDate;
    return (
      <div
        onClick={event => (!!isDateOffLimits || isStartDateAfterEnd || isEndDateAfterStart) && event.stopPropagation()}
        className={classnames(
          (isDateOffLimits || isStartDateAfterEnd || isEndDateAfterStart) && styles.WeekIndexDisabled,
        )}
      >
        {getWeek(date, defaultWeekOptions)}
      </div>
    ) as any;
  };

  const datePickerProps = {
    popperClassName: styles.DateRangePickerContainer__DateRangePicker,
    showPopperArrow: false,
    // tslint:disable-next-line:object-shorthand-properties-first
    startDate,
    calendarStartDate: 0,
  };

  const onStartDateChange = (date: Date) => {
    if (date === null || (maximumDate && date > maximumDate) || (minimumDate && date < minimumDate)) {
      // tslint:disable-next-line:no-parameter-reassignment
      date = undefined as unknown as Date;
      onStartDateSelect(date as Date);
      return;
    }
    if (date !== undefined) {
      onStartDateSelect(date as Date);
    }
  };

  // const renderClearButton = (callback: (value: undefined) => void) => (
  //   <ButtonIcon
  //     className={styles['Input__clearIcon']}
  //     icon={Close}
  //     iconDataTestId="clear-button-icon"
  //     iconSize={20}
  //     onClick={() => callback(undefined)}
  //   />
  // );

  return (
    <div
      data-test-id="DateRangePickerContainer"
      className={classnames(styles.DateRangePickerContainer, colorKeyboardSelected && styles.KeyboardSelectedMonth)}
    >
      <div className={classnames('grid grid-flow-col auto-cols-max gap-4', direction === 'row' && styles.DatePickerGrid)}>
        <div
          data-test-id={startTestId}
          className={classnames(styles.DatePickerGridCell, direction === 'row' && styles.DatePickerGridCellRow)}
        >
          <DatePicker
            onYearChange={onYearChangeMonthView}
            onCalendarOpen={onCalendarOpenMonthView}
            placeholderText={calendarView === CalendarView.Month ? 'Start Date' : 'Week Year'}
            selected={startDate}
            onChange={onStartDateChange}
            selectsStart={calendarView === CalendarView.Month}
            endDate={endDate}
            minDate={minimumDate}
            formatWeekNumber={formatWeekNumber(true)}
            onWeekSelect={(firstDayOfWeek: Date) => {
              const isBetweenDates =
                (minimumDate ? firstDayOfWeek >= minimumDate : true) &&
                (maximumDate ? firstDayOfWeek <= maximumDate : true);
              if (endDate && isBetweenDates && firstDayOfWeek > endDate) {
                return;
              }

              if (endDate && isBetweenDates && firstDayOfWeek < endDate) {
                return onStartDateSelect(firstDayOfWeek);
              }

              if (isBetweenDates) {
                return onStartDateSelect(firstDayOfWeek);
              }
            }}
            maxDate={endDate && endDate !== maximumDate ? endDate : maximumDate}
            openToDate={startDate || (endDate && startOfWeek(endDate, { weekStartsOn })) || undefined}
            {...datePickerProps}
            {...(calendarView === CalendarView.Week && {
              dayClassName: date =>
                classnames({
                  [styles['react-datepicker__day--selected']]: isSameWeek(date, endDate || startDate || 0, {
                    weekStartsOn,
                  }),
                  [styles['react-datepicker__day--first-selected']]:
                    isSameDay(date, startDate || 0) || isSameDay(date, endDate || 0),
                }),
              renderCustomHeader: props => (
                <CustomHeader
                  {...props}
                  minimumDate={minimumDate}
                  maximumDate={maximumDate}
                  startDate={startDate}
                  endDate={endDate}
                  isStart
                />
              ),
              dateFormat: getDateFormat(startDate) || undefined,
              ...weekPickerProps,
              customInput: <CustomInput />,
            })}
            {...(calendarView === CalendarView.Month && monthPickerProps)}
          />
          {/* {isClearable && startDate && renderClearButton(onStartDateSelect)} */}
        </div>
        <div
          data-test-id={endTestId}
          className={styles.DatePickerGridCell}
        >
          {/* {isClearable && endDate && renderClearButton(onEndDateSelect)} */}
          <DatePicker
            onYearChange={onYearChangeMonthView}
            onCalendarOpen={onCalendarOpenMonthView}
            placeholderText={calendarView === CalendarView.Month ? 'End Date' : 'Week Year'}
            selected={endDate}
            onChange={date => onEndDateSelect(date as Date)}
            endDate={endDate || undefined}
            formatWeekNumber={formatWeekNumber(false)}
            minDate={startDate || minimumDate}
            selectsEnd={calendarView === CalendarView.Month}
            maxDate={maximumDate}
            popperPlacement="bottom-end"
            openToDate={
              (endDate && startOfWeek(endDate, { weekStartsOn })) || (startDate && add(startDate, { weeks: 1 })) || undefined
            }
            onWeekSelect={(firstDayOfWeek: Date) =>
              (minimumDate ? firstDayOfWeek > minimumDate : true) &&
              (maximumDate ? firstDayOfWeek < maximumDate : true) &&
              (startDate ? firstDayOfWeek > startDate : true) &&
              onEndDateSelect(firstDayOfWeek)
            }
            {...datePickerProps}
            {...(calendarView === CalendarView.Week && {
              dayClassName: date =>
                classnames({
                  [styles['react-datepicker__day--selected']]: isSameWeek(date, endDate || startDate || 0, {
                    weekStartsOn,
                  }),
                  [styles['react-datepicker__day--first-selected']]:
                    isSameDay(date, startDate || 0) || isSameDay(date, endDate || 0),
                }),
              customInput: <CustomInput />,
              dateFormat: getDateFormat(endDate) || undefined,
              renderCustomHeader: props => (
                <CustomHeader
                  {...props}
                  minimumDate={minimumDate}
                  maximumDate={maximumDate}
                  startDate={startDate}
                  endDate={endDate}
                  isStart={false}
                />
              ),
              ...weekPickerProps,
            })}
            {...(calendarView === CalendarView.Month && monthPickerProps)}
          />
        </div>
      </div>
    </div>
  );
};
