import { Auth } from '@aws-amplify/auth';
import * as React from 'react';
import Button from '../system/Button/Button';
import InlineAlert, { InlineAlertType } from '../system/InlineAlert/InlineAlert';
import Input from '../system/Input/Input';
import { AuthState } from './authenticator';
import { getPasswordPolicyErrorMessage } from './errorMessages';
import styles from './authButtons.module.scss';

interface IProps {
  authData: any;
  onStateChange: (newState: AuthState, data?: any) => void;
}

interface IState {
  errorMessage: string | null;
}

interface ErrorMessage {
  code?: string;
  message: string;
}

export default class RequireNewPassword extends React.Component<IProps, IState> {
  private inputs: { [key: string]: string | undefined } = {};

  constructor(props: IProps) {
    super(props);

    this.state = { errorMessage: null };
  }

  async change() {
    this.setState({ errorMessage: null });

    const user = this.props.authData;
    const { newPassword } = this.inputs;
    const { requiredAttributes } = user.challengeParam;

    if (requiredAttributes && requiredAttributes.length) {
      throw new Error(`Unexpected required attributes: ${requiredAttributes.join(', ')}`);
    }

    if (!newPassword) {
      this.setState({ errorMessage: 'Password cannot be empty' });
      return;
    }

    try {
      const updatedUser = await Auth.completeNewPassword(user, newPassword || '', {});

      // completeNewPassword does not seem to clear the new password required flag
      if (updatedUser.challengeName && updatedUser.challengeName !== 'NEW_PASSWORD_REQUIRED') {
        throw new Error(`Unexpected challengeName: '${updatedUser.challengeName}'`);
      }

      this.checkContact(updatedUser);
    } catch (err) {
      const error = err as ErrorMessage;
      let errorMessage = error.message ? error.message: 'An unexpected error occurred';

      if (error.code && error.code === 'InvalidPasswordException') {
        errorMessage = getPasswordPolicyErrorMessage('New password');
      }

      this.setState({ errorMessage });
    }
  }

  checkContact(user: any) {
    this.props.onStateChange('signedIn', user);
  }

  handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    this.inputs[name] = value;
  }

  async onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.keyCode === 13) {
      await this.change();
    }
  }

  render() {
    const { onStateChange } = this.props;
    const { errorMessage } = this.state;

    return (
      <div>
        <h1>Change password</h1>

        {
          errorMessage
            ? <InlineAlert type={InlineAlertType.DataError}>{errorMessage}</InlineAlert>
            : null
        }

        <p>
          You must change your password before you continue using the application.
        </p>

        <div>
          <Input
            type="password"
            label="New Password"
            name="newPassword"
            onChange={e => this.handleInputChange(e as any)}
            // onKeyDown={e => this.onKeyDown(e)}
          />
        </div>
        <div className={styles.buttonContainer}>
          <Button
            secondary={true}
            onClick={() => onStateChange('signIn')}
            testId="signIn">
            Back to sign in
          </Button>{' '}
          <Button
            onClick={() => this.change()}
            testId="changePassword">
            Change
          </Button>
        </div>
      </div>
    );
  }
}
